import { toValue } from 'vue'
// Exports

export function toDate(input, time = false) {
  var value = toValue(input)
  
  if (!value) return null

  return dayjs(value).toDate()
}

export function formatDate(input, time = false, format='MM/DD/YYYY') {
  var value = toValue(input)
  
  if (!value) return null

  if (time) {
    value = dayjs(value).format(`${format} HH:mm`)
  } else {
    value = dayjs(value).format(format)
  }
  
  return value
}

export function formatCurrency(input, isRounded = true) {
  var value = toValue(input)
  
    if (typeof value !== "number") {
        return value;
    }

    if (isRounded){
      return currencyFormatterRounded.format(Math.round(value));
    }else{
      return currencyFormatterWithPennies.format(value);  
    }
    
}

export function formatNum(input, numDigits = 2) {
  var value = toValue(input)
  
  if (typeof value !== "number") {
      return value;
  }

  let options = {minimumFractionDigits: 1, maximumFractionDigits: numDigits}

  if(numDigits == 0){
    options = {minimumFractionDigits: 0, maximumFractionDigits: 0} 
  }

  return new Intl.NumberFormat('en-US', options).format(value)
}

export function formatDateRange(sDate, eDate, formatString='MMM DD, YYYY') {
  const startDate = dayjs(sDate).format(formatString)
  const endDate = dayjs(eDate).format(formatString)
  const dateStr = `${startDate} - ${endDate}`
  return dateStr
}

export function formatDateToNow(input) {
  return dayjs().to( dayjs(input) ) // ex: 3 days ago
}

// Private helpers

const currencyFormatterRounded = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0, // don't show pennies
})

const currencyFormatterWithPennies = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2, // don't show pennies
})

// const dateFormats = {
//   shortDate: 'MM/DD/YY',
//   shortDateTime: 'MM/DD/YY HH:mm',
// }