<template lang="pug">
//- BlockUI provides a shadow overlay
//- ProgressSpinner uses css class area-loading-spinner or mini-loading-spinner
BlockUI.area-loading-overlay(:key="(Math.random() + 1).toString(36).substring(7)" :blocked="loadingDebounced")
  ProgressSpinner(
    v-show="loadingDebounced" 
    strokeWidth="3" 
    animationDuration=".5s"
    :class="spinnerCssClasses"
  )

  //- Contents of the loading area renders in the default slot:
  slot()

  //- pre props: {{ props.loading }}
  //- pre debou: {{ loadingDebounced }}

</template>

<script setup>
  import { computed, ref, watch, watchEffect } from 'vue'
  import _debounce    from 'lodash/debounce'

  const props = defineProps({
    loading:  { type: Boolean, default: false },
    size:     { type: String, default: null },
    position: { type: String, default: 'center' },
    immediate: { required: false, type: Boolean, default: false },
  })

  const spinnerCssClasses = computed(() => {
    let sizeClass = props.size == 'mini' ? 'mini-loading-spinner' : 'area-loading-spinner'
    let positionClass = (props.position == 'top') ? 'top-position' : ''
    return [sizeClass, positionClass].join(' ')
  });

  const loadingDebounced = ref(false)

  watch(() => props.loading, (val) => {
    // console.log('watch props', val)
    if (val) {
      loadingDebounced.value = val
    } else {
      _debounce(function () {
        loadingDebounced.value = val
      }, 200)()      
    }
  },  { immediate: props.immediate })

  // function devtest(ev) {
  //   console.log('LoadingArea', ev)
  // }
</script>

<!-- <style scoped>
:deep(.p-blockui) {
    background-color: var(--teal-500);
}
</style> -->