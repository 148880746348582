import api from './api' 

const passwordService = {
  create(params){
    return api.post('auth/passwords', params)
      .then(response => response.data)
  },
  getEdit(id, params){
    return api.get(`auth/passwords/${id}/edit`, params)
      .then(response => response.data)
      .then(data => data.data)
  },
  putUpdate(id, params){
    return api.put(`auth/passwords/${id}`, params)
      .then(response => response.data)
  },  
}

export default passwordService;  