// Helper functions mapping a Deal's status code to its proper CSS class

const dealStatus = {
  cssClass(code) {
    return this.step(code) || 'deprecated';
  },

  statuses() {
    return DEAL_STATUSES
  },

  stage(code) {
    var found = DEAL_STATUSES.filter(status => status.code == code)
    if (found.length > 0) {
      return found[0].stage;
    } else {
      return '';
    }
  },

  step(code) {
    var found = DEAL_STATUSES.filter(status => status.code == code)
    if (found.length > 0) {
      return found[0].step;
    } else {
      return '';
    }
  },

  sortOrder(code) {
    var found = DEAL_STATUSES.filter(status => status.code == code)
    if (found.length > 0) {
      return found[0].order;
    } else {
      return 999;
    }
  },
}

const DEAL_STATUSES = [
  // -----------------
  // PRE-UNDERWRITING
  // -----------------

  { code: 'N',   stage: 'pre-underwriting', step: 'screening', order: 100 },
  // sales-queue
  { code: 'N1',   stage: 'pre-underwriting', step: 'sales-queue', order: 105 },
  { code: 'N2',   stage: 'pre-underwriting', step: 'sales-queue', order: 110 },
  { code: 'N3',   stage: 'pre-underwriting', step: 'sales-queue', order: 115 },
  { code: 'N4',   stage: 'pre-underwriting', step: 'sales-queue', order: 120 },
  { code: 'N5',   stage: 'pre-underwriting', step: 'sales-queue', order: 125 },
  { code: 'LCL',  stage: 'pre-underwriting', step: 'sales-queue', order: 130 },
  { code: 'NLCL', stage: 'pre-underwriting', step: 'sales-queue', order: 135 },
  { code: 'CI',   stage: 'pre-underwriting', step: 'sales-queue', order: 140 },
  // client-contacted
  { code: 'KS',   stage: 'pre-underwriting', step: 'client-contacted', order: 145 },
  { code: 'KSNC', stage: 'pre-underwriting', step: 'client-contacted', order: 150 },
  { code: 'P',    stage: 'pre-underwriting', step: 'client-contacted', order: 155 },
  { code: 'PA',   stage: 'pre-underwriting', step: 'client-contacted', order: 160 },
  { code: 'PDH',  stage: 'pre-underwriting', step: 'client-contacted', order: 165 },
  { code: 'PTA',  stage: 'pre-underwriting', step: 'client-contacted', order: 170 },
  { code: 'CKNS', stage: 'pre-underwriting', step: 'client-contacted', order: 175 },
  // application-returned
  { code: 'KR',   stage: 'pre-underwriting', step: 'application-returned', order: 180 },

  // -----------------
  // UNDERWRITING
  // -----------------

  // due-diligence
  { code: 'DD',   stage: 'underwriting', step: 'due-diligence', order: 200 },
  // underwriting
  { code: 'UW',   stage: 'underwriting', step: 'underwriting', order: 205 },
  { code: 'FC',   stage: 'underwriting', step: 'underwriting', order: 210 },
  // docs-sent
  { code: 'DS',   stage: 'underwriting', step: 'docs-sent', order: 215 },
  { code: 'DSNU', stage: 'underwriting', step: 'docs-sent', order: 220 },
  // docs-returned
  { code: 'DR',   stage: 'underwriting', step: 'docs-returned', order: 225 },
  { code: 'RF',   stage: 'underwriting', step: 'docs-returned', order: 230 },

  // -----------------
  // FUNDING
  // -----------------
  
  // funding
  { code: 'FU',     stage: 'funding', step: 'funding', order: 300 },
  { code: 'FUMA',   stage: 'funding', step: 'funding', order: 305 },
  { code: 'FUNL',   stage: 'funding', step: 'funding', order: 310 },
  // filing
  { code: 'FUWF',   stage: 'funding', step: 'filing', order: 315 },
  { code: 'FHWF',   stage: 'funding', step: 'filing', order: 320 },
  { code: 'FUWM',   stage: 'funding', step: 'filing', order: 325 },
  // holdback
  { code: 'FUH',    stage: 'funding', step: 'holdback', order: 330 },
  { code: 'FUHR',   stage: 'funding', step: 'holdback', order: 335 },

  // -----------------
  // POST FUNDING
  // -----------------
  
  // pay-off
  { code: 'PF',     stage: 'post-funding', step: 'pay-off', order: 400 },
  { code: 'PP',     stage: 'post-funding', step: 'pay-off', order: 405 },
  { code: 'PRP',    stage: 'post-funding', step: 'pay-off', order: 410 },
  { code: 'PRNP',   stage: 'post-funding', step: 'pay-off', order: 412 },
  // write-off
  { code: 'WO',     stage: 'post-funding', step: 'write-off', order: 415 },
  { code: 'WOFU',   stage: 'post-funding', step: 'write-off', order: 420 },
  { code: 'FP',     stage: 'post-funding', step: 'write-off', order: 425 },

  // -----------------
  // DEPRECATED/OLD STATUSES
  // -----------------
  
  { code: 'NG',  stage: 'deprecated', step: 'deprecated', order: 900 },
  { code: 'B',   stage: 'deprecated', step: 'deprecated', order: 905 },
  { code: 'G',   stage: 'deprecated', step: 'deprecated', order: 910 },
  { code: 'OH',  stage: 'deprecated', step: 'deprecated', order: 915 }
]

export default dealStatus;