<script setup>
  import dealStatus from '@/utils/dealStatus'
  import { computed } from 'vue'

  const props = defineProps({
    deal:      { type: Object, required: true },
    noMargins: { type: Boolean, default: false },
    status:    { type: String },
    isLinked:  { type: Boolean, default: true },
    target:    { type: String,  default: null },
  })

  const status     = computed(() => props.status || props.deal.status);
  const dealNumber = computed(() => props.deal.deal_number);
  const initials   = computed(() => props.deal.fo_initials);
  const isNoGood   = computed(() => props.deal.no_good_flag);
  const isOnHold   = computed(() => props.deal.on_hold_flag);
  const isHoldBack = computed(() => props.deal.holdback_flag);
</script>

<template lang="pug">
template(v-if="isLinked")
  RouterLink(:to=" '/deals/' + props.deal.id " style="display: contents;" :target="props.target")
    .deal-tag-slim(:class="dealStatus.cssClass(status)")
      .status {{ status }}
      .initials {{ initials }}
      .deal-number(:class="{'on-hold': Boolean(isOnHold), 'holdback': Boolean(isHoldBack), 'no-good': Boolean(isNoGood)}") {{ dealNumber }}

template(v-else)
  .deal-tag-slim(:class="dealStatus.cssClass(status)")
    .status {{ status }}
    .initials {{ initials }}
    .deal-number(:class="{'on-hold': Boolean(isOnHold), 'holdback': Boolean(isHoldBack), 'no-good': Boolean(isNoGood)}") {{ dealNumber }}
  
</template>

