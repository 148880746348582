<template lang="pug">

.grid.dashboard-section-header
  div(class="col-6 lg:col-4 flex align-items-center")
      template(v-if="userList.length > 1")
        .flex-start
          h3.mb-0 Pipeline for {{ selectedOfficer.full_name }}
          UserAvatarGroup.mx-2(:users="userList")
      template(v-if="userList.length == 1")
        .flex-start
          h3.my-0 Pipeline for
          UserAvatar.mx-2(:user="userList[0]")
          h3.my-0 {{ selectedOfficer.full_name }}

  
  div(class="col-6 lg:col-2 flex-end")
    Dropdown(
      v-model="groupBy"
      :options="groupingOptions"
      optionLabel="label"
      optionValue="value"
      style="min-width: 13rem;"
    )
  div(class="col-12 lg:col-6 flex align-items-center justify-content-end")
    .color-panels.justify-content-end
      .color-panel.cp-small.cp-primary.cp-outlined.cp-slim
        .big-text {{grandTotals?.count_deal_number}}
        .small-text Deals

      .color-panel.cp-small.cp-orange.cp-outlined.cp-slim
        .big-text  {{ formatCurrency( grandTotals?.sum_net_advance ) }}
        .small-text Net Advance

      .color-panel.cp-small.cp-indigo.cp-outlined.cp-slim
        .big-text {{ formatCurrency( grandTotals?.sum_agp ) }}
        .small-text Total AGP

LoadingArea(:loading="isLoading")
    //- div(style="overflow-x: scroll; background-color: var(--surface-0);" v-show="!isLoading")
    div(style="width:100%;overflow-x:auto;")
      table.table.reports-table(style="background-color: var(--surface-0);" v-show="!isLoading")

        tbody(v-for="group in groupedTableData")

            tr.group-header-row
              td.border-none(colspan='10')
                .text-2xl.font-bold.ml-2.my-1
                  | {{ group['groupName'] }} &nbsp;
                  small.small.grey.text(v-if=" group['groupName']=='Pending Follow Up' " style="font-size: .9em;") (FO Follow Up >= {{ '???' }})

            tr.header-row
              td.text-center FO
              td.text-center Status
              td Name / Estate
              td Phone / Email
              td Client Req. / Net Adv.
              td.text-center AGP
              td Kit Sent / Returned
              td.text-center Age
              td Source
              td Attorney

            template(v-for="row in group['data']")
                tr.main-row
                    td.text-center
                        .ui.label {{row['fo_initials']}}
                    td.flex-center
                      DealBadge(:deal="row" :noMargins="true")

                    td.deal-name
                      h5.mb-1
                        DealLink.link(:deal="row")
                      h6.my-0
                        span.text.label {{ row['estate_or_trust'] }}: &nbsp;
                        EstateLink.link(:estate="{id: row['estate_id']}" style="font-size: 1.0rem") {{ row['estate_or_trust_name'] }}

                    td
                        .mb-1 {{ row['phone'] }}
                        MailToLink(:deal="row")

                    td
                      .mb-1.flex
                        .text-gray-500.w-4 Req:
                        span {{ row['client_request_amount'] }}
                      .mb-1.flex
                        .text-gray-500.w-4 Net:
                        span {{ formatCurrency(row['net_advance']) }}

                    td.text-center {{ formatCurrency(row['agp']) }}

                    td
                      .mb-1.flex
                        .text-gray-500.w-4 Sent:
                        span {{ formatDate(row['kit_sent_date']) }}
                      .mb-1.flex
                        .text-gray-500.w-4 Retn:
                        span {{ formatDate(row['kit_returned_date']) }}

                    td.text-center {{ row['days_ago'] }}

                    td
                      .mb-1 {{ row['source_code'] }}
                      .text-gray-500 {{ row['web_source_code'] }}

                    td {{ row['attorney_name'] }}

            tr.subtotal-row.secondary-row
                td.text-right(colspan="4")
                   .text-lg.font-bold {{ group['groupName'] }} Totals:

                td.no-left-border(colspan="6")
                  .color-panels
                    .color-panel.cp-small.cp-primary.cp-slim.cp-outlined.cp-no-border
                      .big-text {{group.totals.count_deal_number}}
                      .small-text.ml-2 Deals
                    .color-panel.cp-small.cp-orange.cp-slim.cp-outlined.cp-no-border
                      .big-text {{ formatCurrency(group.totals.sum_net_advance) }}
                      .small-text.ml-2 Net Adv
                    .color-panel.cp-small.cp-indigo.cp-slim.cp-outlined.cp-no-border
                      .big-text {{ formatCurrency(group.totals.sum_agp) }}
                      .small-text.ml-2 AGP
        
            tr.spacer-row: td.h-2rem(colspan="10")

    .grid.dashboard-section-header(v-if="grandTotals && groupedTableData?.length > 1" style="margin-top: 1rem;")
      div(class="col-12 lg:col-6 flex align-items-center")
        h3.mb-0 GRAND TOTALS

      div(class="col-12 lg:col-6 flex justify-content-end align-items-center")
        .color-panels.justify-content-end
          .color-panel.cp-small.cp-primary.cp-outlined.cp-slim
            .big-text {{grandTotals?.count_deal_number}}
            .small-text Deals

          .color-panel.cp-small.cp-orange.cp-outlined.cp-slim
            .big-text  {{ formatCurrency( grandTotals?.sum_net_advance ) }}
            .small-text Net Advance

          .color-panel.cp-small.cp-indigo.cp-outlined.cp-slim
            .big-text {{ formatCurrency( grandTotals?.sum_agp ) }}
            .small-text Total AGP

</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { RouterLink } from 'vue-router'
import _filter from 'lodash/filter'
import _groupBy from 'lodash/groupBy'
import _orderBy from 'lodash/orderBy'
import _round from 'lodash/round'
import _sortBy from 'lodash/sortBy'
import _sumBy from 'lodash/sumBy'

import { formatDate, formatCurrency } from '@/utils/format'
import DealBadge from '@/components/DealBadge.vue'
import pipelineService  from '@/services/dashboard/pipeline.service'

import DealLink from '@/components/DealLink.vue'
import EstateLink from '@/components/EstateLink.vue'

const props = defineProps({
  selectedOfficer: {
    type: Object,
    required: true
  }
});

const groupingOptions = [
  {
    label: 'Status', value: 'group_by_status_name'
  },
  {
    label: 'Funding Officer', value: 'fo_name'
  },
  {
    label: 'Source', value: 'source_code'
  },    
]

const isLoading        = ref(false)
const rawTableData     = ref([])
const groupedTableData = ref({})
const grandTotals      = ref({})
const groupBy          = ref('group_by_status_name')
const userList         = ref([])

const loadDeals = (fo_initials) => {
  const params = {
    fo_initials: fo_initials
  }

  isLoading.value = true
  pipelineService.getDeals(params).then((data) => {
    rawTableData.value = data.reportData // rawData/rows
    grandTotals.value = getGrandTotals(data.reportData)
    groupedTableData.value = groupRawData('group_by_status_name') // 'fo_name', 'source_code'
    userList.value = data.userList
  })
  .finally(() => isLoading.value = false)
}

const groupRawData = (groupFieldName) => {
	let firstGrouping = _groupBy(rawTableData.value, groupFieldName);
	var groupedReportData = [];

	// populate reportData with group name, rows and totals
	Object.keys(firstGrouping).forEach( (key) => {
	  var totals = {};
	  var rows = firstGrouping[key];

	  // SORTING: add sort order by looking up deal status order, add this field to all records and sort
	  rows.forEach( (row) => {
	    row['sort_order'] = row['status_sort'];
	    // row['sort_class_name'] = dealStatus.cssClass(row['status']);
	  });
	  rows = _orderBy(rows, ['sort_order', 'full_name'], ['desc', 'asc']);

	  // TOTALS for this group of rows
	  totals['count_deal_number'] = rows.length;
	  // Sums
	  totals['sum_net_advance'] = _sumBy(rows, 'net_advance') || 0;
	  totals['sum_agp']         = _sumBy(rows, 'agp') || 0;
	  // Averages
	  totals['ave_days_ago'] = getAve(rows, 'days_ago', 1);

	  var groupSortOrder;
	  switch(groupFieldName) {
	    case "group_by_status_name":
	      groupSortOrder = rows[0]['group_by_status_sort_order'];
	      break;
	    default:
	      groupSortOrder = key;
	  }
	  // console.log("totals", totals);
	  groupedReportData.push({groupName: key, groupSortOrder: groupSortOrder, data: rows, totals: totals})
	})

	groupedReportData = _sortBy(groupedReportData, 'groupSortOrder');

	return groupedReportData;
}

const getGrandTotals = (rows) => {
    var totals = {};
    // Counts
    totals['count_deal_number'] = rows.length;

    // Sums
    totals['sum_net_advance'] = _sumBy(rows, 'net_advance');
    totals['sum_agp']         = _sumBy(rows, 'agp');

    // Averages
    totals['ave_days_ago'] = getAve(rows, 'days_ago', 1);

    // console.log("totals", totals);

    return totals;
}

// TODO: Extract getAve into a global function
const getAve = (items, key, precision = -1) => {
    let theSum = _sumBy(items, key);
    let theCount = _filter(items, (item) => item[key] != undefined).length;
    let ave = theCount == 0 ? null : theSum / theCount;

    if (precision > -1) {
    return _round(ave, precision);
    } else {
    return ave;
    }
}

watch(groupBy, (newVal, oldVal) => {
  groupedTableData.value = groupRawData(newVal)
})

// watch selected Funding Officer
watch(() => props.selectedOfficer, (newVal, oldVal) => {
  loadDeals(newVal.initials);
})

onMounted(() => {
  // fetch & set AGP Growth datasets
  loadDeals(props.selectedOfficer.initials);
})
</script>