import api from '@/services/api'

const pipelineService = {
  getCharts(params) {
    return api.get('dashboards/pipeline/fo_performance_chart', params)
      .then(response => response.data)
      .then(data => data.data)
  },
  getDeals(params) {
    return api.get('dashboards/pipeline/pipeline', params)
      .then(response => response.data)
      .then(data => data.data)
  },
  getPayoffsChart(params) {
    return api.get('dashboards/pipeline/payoffs_chart', params)
      .then(response => response.data)
      .then(data => data.data)
  },
}

export default pipelineService;