// console.log('rollbar.config.js', import.meta.env.MODE, import.meta.env.MODE !== 'development',  process.env.NODE_ENV !== 'development' )
export default {
  enabled: import.meta.env.MODE !== 'development', // << this line doesn't actually disable anything, but instructions say to include it
  accessToken: import.meta.env.VITE_ROLLBAR_POST_CLIENT_ITEM_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: import.meta.env.MODE, //import.meta.env.MODE or process.env.NODE_ENV ??
    client: {
      javascript: {
        source_map_enabled: true,
        // code version is required for sourcemaps
        code_version: import.meta.env.VITE_CODE_VERSION,
        // Optionally have Rollbar guess which frames the error was 
        // thrown from when the browser does not provide line 
        // and column numbers.
        guess_uncaught_frames: true,
      }
    }
  },
}