<template lang="pug">
//- TransitionGroup is automatically included as a Vue 3 component - see Vue docs for usage
TransitionGroup(
        :tag="props.tag"
        :css="false"
        @before-enter="onBeforeEnter"
        @enter="onEnter"
        @leave="onLeave"
    )

    //- slot == List of DOM items to be animated
    //- Each DOM item requires:
    //-     :key="item.id" in order to properly animate
    //-     :data-index="index" to show the stagger effect using JS
    slot()
</template>

<script setup>
    import { computed, ref, watch, watchEffect } from 'vue'
    import { animate, spring } from "motion"

    const props = defineProps({
    	effect:  { type: String, default: 'fadeRight' },
    	tag:     { type: String, default: 'div' },
    })

    function onBeforeEnter(el) {
        animate(el,
          { x: -100, opacity: 0 },
          { duration: 0.4, }
        )
        // console.log('onBeforeEnter')
    }

    function onEnter(el, done) {
        animate(
          el,
          { x: 0, opacity: 1 },
          {
            delay: (el.dataset.index || 0) * 0.03,
            duration: 0.4,
            easing: spring(), //[.22, .03, .26, 1]
          }
        ).finished.then(done)
        // console.log('onEnter', (el.dataset.index || 0) * 0.03)
    }

    function onLeave(el, done) {
        animate(
          el,
          { x: -200, opacity: 0 },
          {
            delay: (el.dataset.index || 0) * 0.01,
            duration: 0.3,
            // easing: [.22, .03, .26, 1]
          }
        ).finished.then(done)
        // console.log('onLeave', done)
    }
</script>

<!-- <style>
</style> -->