<script setup>
import { useAuthStore }  from '@/stores'
import { useRouter }     from 'vue-router';
import { useToast }      from 'primevue/usetoast';

const authStore = useAuthStore();
const router = useRouter();
const toast = useToast();

const onLogout = () => {
  let firstName = authStore.currentUser.first_name
  authStore.logout();
  toast.add({severity: 'success', summary: `Goodbye ${firstName}!`, detail: 'You have logged out successfully', life: 5000 });
  router.replace('/login')
}
</script>
  
<template>

<a v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'px-scalein', leaveToClass: 'hidden', leaveActiveClass: 'px-fadeout', hideOnOutsideClick: 'true' }" v-ripple class="cursor-pointer">
    <img class="border-round-xl" :src="authStore.currentUser?.avatar_url" alt="Profile" />
    <!-- <img class="border-round-xl" src="/demo/images/avatar-m-1.jpg" alt="Profile" /> -->
</a>
<ul :class="'topbar-menu active-topbar-menu p-4 w-15rem   z-5 hidden'">

    <li role="menuitem" class="m-0 mb-3">
        <router-link
            :to="`/users/${authStore.currentUser?.id}/settings`"
            class="flex align-items-center hover:text-primary-500 transition-duration-200"
            v-styleclass="{ selector: '@grandparent', enterClass: 'hidden', enterActiveClass: 'px-scalein', leaveToClass: 'hidden', leaveActiveClass: 'px-fadeout', hideOnOutsideClick: 'true' }"
        >
            <i class="pi pi-fw pi-cog mr-2"></i>
            <span style="line-height: 1;">My Settings</span>
        </router-link>
    </li>

    <!-- <li class="m-0">
        <hr style="border-top: 1px solid var(--surface-600); margin: 1.2rem 0;">
    </li>

    <li role="menuitem" class="m-0 mb-3">
        <router-link 
            to="/admin/users" 
            class="flex align-items-center hover:text-primary-500 transition-duration-200"
            v-styleclass="{ selector: '@grandparent', enterClass: 'hidden', enterActiveClass: 'px-scalein', leaveToClass: 'hidden', leaveActiveClass: 'px-fadeout', hideOnOutsideClick: 'true' }"
        >
            <i class="pi pi-fw pi-cog mr-2" />
            <span style="line-height: 1;">Manage Users</span>
        </router-link>    
    </li> -->
    
    <li class="m-0">
        <hr style="border-top: 1px solid var(--surface-600); margin: 1.2rem 0;">
    </li>

    <li role="menuitem" class="m-0">
        <RouterLink
            to="#"
            @click.prevent="onLogout()"
            class="flex align-items-center hover:text-primary-500 transition-duration-200"
            v-styleclass="{ selector: '@grandparent', enterClass: 'hidden', enterActiveClass: 'px-scalein', leaveToClass: 'hidden', leaveActiveClass: 'px-fadeout', hideOnOutsideClick: 'true' }"
        >
            <i class="pi pi-fw pi-sign-out mr-2"></i>
            <span style="line-height: 1;">Logout</span>
        </RouterLink>
    </li>
</ul>

</template>

<style scoped lang="scss">
</style>