<script setup>
import { computed, watch, ref, onBeforeUnmount } from 'vue';
import AppTopbar      from '@/_freya_layout/freya_theme/AppTopbar.vue';
import AppConfig      from '@/_freya_layout/freya_theme/AppConfig.vue';
import AppRightMenu   from '@/_freya_layout/freya_theme/AppRightMenu.vue';
import AppBreadcrumb  from '@/_freya_layout/freya_theme/AppBreadcrumb.vue';
import { usePrimeVue } from 'primevue/config';
import { useLayout }  from '@/_freya_layout/freya_theme/composables/layout';

// Use appStore for the global page loader
import { useAppStore } from '@/stores'
const appStore = useAppStore();
import NotificationMessages from '@/components/NotificationMessages.vue'

const $primevue = usePrimeVue();
const { layoutConfig, layoutState, isSidebarActive } = useLayout();
const outsideClickListener = ref(null);
const topbarRef = ref(null);

watch(isSidebarActive, (newVal) => {
    if (newVal) {
        bindOutsideClickListener();
    } else {
        unbindOutsideClickListener();
    }
});

onBeforeUnmount(() => {
    unbindOutsideClickListener();
});

const containerClass = computed(() => {
    return [
        {
            'layout-light': layoutConfig.colorScheme.value === 'light',
            'layout-dark': layoutConfig.colorScheme.value === 'dark',
            'layout-light-menu': layoutConfig.menuTheme.value === 'light',
            'layout-dark-menu': layoutConfig.menuTheme.value === 'dark',
            'layout-light-topbar': layoutConfig.topbarTheme.value === 'light',
            'layout-dark-topbar': layoutConfig.topbarTheme.value === 'dark',
            'layout-transparent-topbar': layoutConfig.topbarTheme.value === 'transparent',
            'layout-overlay': layoutConfig.menuMode.value === 'overlay',
            'layout-static': layoutConfig.menuMode.value === 'static',
            'layout-slim': layoutConfig.menuMode.value === 'slim',
            'layout-slim-plus': layoutConfig.menuMode.value === 'slim-plus',
            'layout-horizontal': layoutConfig.menuMode.value === 'horizontal',
            'layout-reveal': layoutConfig.menuMode.value === 'reveal',
            'layout-drawer': layoutConfig.menuMode.value === 'drawer',
            'layout-static-inactive': layoutState.staticMenuDesktopInactive.value && layoutConfig.menuMode.value === 'static',
            'layout-overlay-active': layoutState.overlayMenuActive.value,
            'layout-mobile-active': layoutState.staticMenuMobileActive.value,
            'p-ripple-disabled': $primevue.config.ripple === false,
            'layout-sidebar-active': layoutState.sidebarActive.value,
            'layout-sidebar-anchored': layoutState.anchored.value
        }
    ];
});

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                layoutState.overlayMenuActive.value = false;
                layoutState.overlaySubmenuActive.value = false;
                layoutState.staticMenuMobileActive.value = false;
                layoutState.menuHoverActive.value = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener);
        outsideClickListener.value = null;
    }
};
const isOutsideClicked = (event) => {
    if (!topbarRef.value) return;

    const sidebarEl = topbarRef?.value.$el.querySelector('.layout-sidebar');
    const topbarEl = topbarRef?.value.$el.querySelector('.topbar-start > button');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};
</script>

<template lang="pug">
//- ---------------------------
//- BlockUI and ProgressSpinner are used together as a global page loading element.
//- Set appStore.isPageLoading to show/hide full page loading state  
//- ---------------------------
BlockUI(:blocked="appStore.isPageLoading" :fullScreen="true")
ProgressSpinner(v-show="appStore.isPageLoading" class="full-page-loading-overlay")

//- ---------------------------
//- Main App Layout
//- ---------------------------
.layout-container(:class="containerClass")
  
  .header
    AppTopbar(ref="topbarRef")
    slot(name="header")

  .layout-content-wrapper

    AppConfig
    .layout-content
      //- <app-breadcrumb></app-breadcrumb>
      
      //- Global Messages
      NotificationMessages

      //- Body Content
      slot

    AppRightMenu
    .layout-mask

  .footer
    slot(name="footer")

</template>

<style lang="scss"></style>
