<template lang="pug">
.bg-white  
  Paginator(
    v-model:first="offset"
    :rows="props.perPage"
    :totalRecords="props.totalCount"
    @page="onPaginate"
    :alwaysShow="true"
    :pt="{root: {style: 'border-radius: 0px;'}}"
  )
  .text-center.font-bold.text-400(v-if="showPageReport") Showing {{first}} to {{last}} of {{totalCount}}
</template>

<script setup>
import { ref, reactive, watchEffect } from 'vue'

const props = defineProps({
  page: { type: Number, required: false, default: 1 },
  perPage: { type: Number, required: true, default: 20 },
  totalCount: { type: Number, required: true, default: 0 },
  showPageReport: { type: Boolean, default: false },
})

const emit = defineEmits({
  changePage: ({currentPage}) => {
    // console.log('emit: changePage', currentPage)
    return Number.isInteger(currentPage)
  }
})

const offset = ref(0);
const currentPage = ref(1);

const first = ref(1)
const last  = ref()

watchEffect(() => {
  offset.value = (props.perPage * (props.page - 1))
  currentPage.value = props.page;
  if (props.totalCount < props.perPage){
    first.value = 1
    last.value  = props.totalCount
  }else{
    first.value =  ((props.page - 1) * props.perPage) + 1
    last.value  = props.perPage * props.page   
  }
})

const onPaginate = (e) => {
  currentPage.value = +e.page + 1
  emit('changePage', {currentPage: currentPage.value})
}
</script>