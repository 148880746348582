<script setup>
import { ref, onUnmounted } from 'vue';
import AppConfig from '@/_freya_layout/freya_theme/AppConfig.vue';
import { useRouter } from 'vue-router';
import passwordService from '@/services/password.service'
import { pageSuccess, pageError }  from '@/modules/HttpResponse'

const router = useRouter();
const email = ref();
const isEmailSent = ref(false)
const isPageLoading = ref(false)

import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/yup';
import * as yup from 'yup';

const initialValues = {
  email: null,
}

const schema = toTypedSchema(
  yup.object({
    email: yup
      .string()      
      .email("Invalid email format")
      .required("Mail is required")
  })
)

const {
  handleSubmit,
  errors,
  setErrors,
  controlledValues,
  defineComponentBinds,
  setFieldValue,
} = useForm({
  validationSchema: schema,
  initialValues: initialValues,
});

const formInputs = ref({
  email: defineComponentBinds('email'),
})

const navigateToDashboard = () => {
  router.push('/');
};

const resetPassword = handleSubmit(values => {
  isPageLoading.value = true
  passwordService
    .create({ password: {email: values.email }})
    .then(response => {
      isEmailSent.value = true
      pageSuccess(response)
    })
    .finally(() => isPageLoading.value = false)
})

onUnmounted(() => isEmailSent.value = false)
</script>

<template lang="pug">

.card(v-if="isEmailSent")
  .text-green-700
    i.mr-4.text-3xl.font-bold.pi.pi-check-circle
    span.text-4xl.font-bold Email Sent
  .my-4.text-lg If your email is registered in our system, an email has been sent with a link to reset your password.
  .my-2
    Button.flex-auto.p-button-outlined(v-ripple='' label='<< Back to login' @click='navigateToDashboard')

.card(v-else)    
  .mb-4
    .text-900.text-xl.font-bold.mb-2 Forgot Password
    span.text-600.font-medium Enter your email to reset your password
  
  form.flex.flex-column(@submit.prevent="resetPassword")
    .p-input-icon-left.w-full
      i.pi.pi-envelope
      InputText#email.w-full.surface-50.border-200.text-center(v-bind="formInputs.email" type='text' placeholder='Email' invalid)
    .my-3.text-lg.text-red-600 {{ errors.email }}  

    .flex.flex-wrap.gap-2.justify-content-between
      Button.flex-auto.p-button-outlined(v-ripple='' label='Cancel' @click='navigateToDashboard')
      Button.flex-auto(v-ripple='' type="submit" label='Submit' :loading="isPageLoading")
      
AppConfig(simple='')
</template>
