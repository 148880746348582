import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useDropdownStore = defineStore('dropdown', () => {
  const noGoodReasons = ref(null)
  const statuses =      ref(null)
  const webSources =    ref(null)

  function reset() {
    noGoodReasons.value   = null
    statuses.value        = null
    webSources.value      = []
  }

  return {
    noGoodReasons,
    statuses,
    webSources,
    
    reset,
  }
},
{
  persist: true,
})
