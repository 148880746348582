<script setup>
  import { reactive, onMounted }     from 'vue';
  import { useRouter }    from 'vue-router';
  import { useToast }     from 'primevue/usetoast';
  import { useAuthStore } from '@/stores'
  import AppConfig        from '@/_freya_layout/freya_theme/AppConfig.vue';

  const authStore = useAuthStore();
  const router = useRouter();
  const toast = useToast();
  const loginData = reactive({email: null, password: null})

  // onMounted(() => {
  //   toast.add({severity: 'info', summary: 'Authentication', detail: 'Please login to continue', life: 5000 })
  // });

  const onSubmit = () => {
    authStore.login({...loginData, session: {...loginData}}).then(
      (data) => {
        // console.log("Login.vue", data)
        toast.add({severity: 'success', summary: `Hello ${data.first_name}!`, detail: 'Login Successful', life: 5000 });

        if(authStore.redirectUrl){
          let path = authStore.redirectUrl
          authStore.setRedirectUrl(null)
          router.push(path);
        }else{
          router.push('/');
        }
        
      },
      (err) => {
        toast.add({ severity: 'warn', summary: 'Login Failed', detail: err.response.data.message, life: 5000 });
      }
    );
  }
</script>

<template lang="pug">
form.formgrid.grid.p-fluid(@submit.prevent="onSubmit")

  .field.col-12
    IconField(iconPosition="left")
      InputIcon.pi.pi-envelope
      InputText.text-center(v-model="loginData.email" type="text" placeholder="Email")

  .field.col-12
    IconField(iconPosition="left")
      InputIcon.pi.pi-lock
      InputText.text-center(v-model="loginData.password" type="password" placeholder="Password")

  .field.col-12.px-2
    Button(type="submit" label="Log In" class="p-ripple w-full mt-3")

RouterLink.font-medium.text-200.mt-4(to="forgot-password") forget password?

AppConfig(simple)
</template>
