<template lang="pug">
TransitionGroup.global-messages-w(
  v-if="notificationStore.messages.length > 0" 
  name="p-message" 
  tag="div"
)
    Message(
      v-for="msg of notificationStore.messages"
      :key="msg.id"
      :severity="msg.severity"
    )
      .flex-between
        .text-xl {{ msg.content }}
        div
          Button.p-button-xs.ml-4(
            v-if="msg.extraInfo"
            @click="toggleShowExtraInfo(msg)"
            severity="secondary"
            :label="buttonText(msg)"
          )

      pre(
        v-if="msg.showExtraInfo"
        class="bg-white-alpha-90 text-800 p-6 font-semibold border-round-2xl text-lg"
        style="text-wrap: balance;"
      )
        | {{ msg.extraInfo?.content }}

      //- pre(style="text-wrap: balance;") msg: {{msg}}
</template>

<script setup>
import { ref } from 'vue'
import { useNotificationStore } from '@/stores'
const notificationStore = useNotificationStore();

function toggleShowExtraInfo(msg) {
  if (msg.showExtraInfo == undefined) msg.showExtraInfo = false
  msg.showExtraInfo = !msg.showExtraInfo
}

function buttonText(msg) {
  return msg.showExtraInfo ? '< Hide Info' : 'More Info...'
}
</script>
