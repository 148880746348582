import _find from 'lodash/find';

// External tooltip start
const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.zIndex = 9999
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.85)';
    tooltipEl.style.borderRadius = '8px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const {chart, tooltip} = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const footerLines = tooltip.footer || [];

    const body = tooltip.body.sort((a,b) => {
      let bNumber = b.after[0]?.replace(/\,/g,'') || 0
      let aNumber = a.after[0]?.replace(/\,/g,'') || 0

      return parseFloat(bNumber) - parseFloat(aNumber)
    })
        
    const tableHead = document.createElement('thead');

    titleLines.forEach(title => {
      const tr = document.createElement('tr');
      tr.style.borderWidth = 0;
      tr.style.textWrap = 'nowrap';
      tr.style.fontSize = '1.25rem';

      const th = document.createElement('th');
      th.style.borderWidth = 0;
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    body.forEach((bodyItem, i) => {
      let bodyLines = bodyItem.lines

      // const colors = tooltip.labelColors[i];
      let datasets = tooltip.dataPoints.map(dataPoint => dataPoint.dataset)
      let dataset = _find(datasets, {'label': bodyItem.before[0]});

      const span = document.createElement('span');
      span.style.background = dataset?.backgroundColor;
      span.style.borderColor = dataset?.borderColor;
      span.style.borderWidth = '2px';
      span.style.marginRight = '10px';
      span.style.height = '10px';
      span.style.width = '10px';
      span.style.display = 'inline-block';

      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = 0;
      tr.style.textWrap = 'nowrap';
      tr.style.fontSize = '1.25rem';

      const td = document.createElement('td');
      td.style.borderWidth = 0;

      const text = document.createTextNode(bodyLines);

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableFoot = document.createElement('tfoot');

    footerLines.forEach(title => {
      const tr = document.createElement('tr');
      tr.style.borderWidth = 0;
      tr.style.textWrap = 'nowrap';
      tr.style.fontSize = '1.25rem';
      tr.style.fontWeight = 'bold';

      const td = document.createElement('td');
      td.style.borderWidth = 0;
      td.style.textAlign = 'center';
      const text = document.createTextNode(title);

      td.appendChild(text);
      tr.appendChild(td);
      tableFoot.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
    tableRoot.appendChild(tableFoot);
  }

  const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

export default externalTooltipHandler;
// External tooltip start