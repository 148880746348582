<template lang="pug">
.field.col.mb-0(style="min-width: 13rem;")
    label(v-if="showLabel") Start Date
    AppCalendar(v-model="dateRange.startDate")
    //- Calendar(v-model="dateRange.startDate"
    //-   showIcon
    //-   :manualInput="false"
    //-   :dateFormat="calendarDateFormat"
    //- )
.field.col.mb-0(style="min-width: 13rem;")
    label(v-if="showLabel") End Date
    AppCalendar(v-model="dateRange.endDate")
    //- Calendar(v-model="dateRange.endDate"
    //-   showIcon
    //-   :manualInput="false"
    //-   :dateFormat="calendarDateFormat"
    //- )
</template>

<script setup>
import { computed, watch } from 'vue'

// const calendarDateFormat    = 'M d, yy';

const props = defineProps({
  dateRange: { type: Object,  required: true },
  showLabel: { type: Boolean, required: false, default: true },
})

const emit = defineEmits(['change'])

watch(() => [props.dateRange.startDate, props.dateRange.endDate], (valueArr) => {
  emit('change')
}, {deep: true})

// const dateRangeText = computed(() => {
//   const startDate = dayjs(dateRange.startDate?.value).format("MMM DD, YYYY")
//   const endDate = dayjs(dateRange.endDate?.value).format("MMM DD, YYYY")
//   const dateStr = `${startDate} - ${endDate}`
//   return dateStr
// })
</script>
