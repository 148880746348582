<script setup>
  import { ref, computed, watch, onMounted } from 'vue';

  const props = defineProps({
    modelValue:   {},
    iconPosition: { type: String, default: 'left' },
    placeholder:  { type: String }, 
  })

  const emit  = defineEmits(['update:modelValue'])

  const model = ref()

  const onChangeInput = (e) => {
    emit('update:modelValue', e.value)
  }

  watch(() => props.modelValue, (newVal) => {
    model.value = newVal
  })

  onMounted(() => {
    model.value = props.modelValue
  })
</script>

<template lang="pug">
  InputNumber(v-model="model" :placeholder="placeholder" mode="currency" currency="USD" locale="en-US" @input="onChangeInput")  
</template>