import api from './api' 

const appService = {
  init() {
    return api.get('get_app_data')
      .then(response => {
        return response.data.data;
      });
  },
  get_employee_dropdown(params={}) {
    return api.get('employee_dropdown', params)
      .then(response => {
        return response.data.data;
      });
  },
}

export default appService;