<template lang="pug">
RouterLink.estate-link(:to=" '/estates/' + props.estate.id " :target="props.target")
  template(v-if="slots.default")
    slot
  template(v-else)
    span {{ props.estate.full_name || "ESTATE NAME IS BLANK" }}
</template>

<script setup>
import { useSlots } from 'vue'
import { RouterLink } from 'vue-router'

const slots = useSlots();

const props = defineProps({
  estate: {
    type: Object,
    default: {},
  },
  target: {
    type: String,
    default: null,
  },
})
</script>