import Rollbar from 'rollbar';
import baseRollbarConfig from '/rollbar.config';
import _merge from 'lodash/merge'

const rollbar = new Rollbar(baseRollbarConfig);

export default {
  install(app, options) {
    app.config.errorHandler = (error, vm, info) => {

      // ----------------------------------------------------
      // !!!!! IMPORTANT !!!!!  (2024-05-06 -kamal)
      // ----------------------------------------------------
      // Do NOT use the `vm` object (2nd arg of errorHandler) anywhere inside of this function. 
      //    If you do the production
      //    app will *freeze* anytime this function is called. Meaning, anytime a JS error occurs
      //    and this errorHandler is called the whole app will freeze and need to be reloaded.
      //    This is because vm refers to the whole Vue component app and even trying to `console.log(vm)`
      //    here will throw a warning in dev, or completely freeze in production.
      // ----------------------------------------------------
      // If you do add the vm object here you will see the following warning in development env
      //    in Chrome DevTools (and the production build will completely freeze):
      //        " Avoid app logic that relies on enumerating keys on a component instance. 
      //          The keys will be empty in production mode to avoid performance overhead. "
      // ----------------------------------------------------

      // ----------------------------------------------------
      // Below we are merging in extra payload data, like the current user id and name
      // We need to access the Pinia store (authStore) which is not accessible in this file
      //    so we get this info from main.js and send it here in the options var
      // ----------------------------------------------------
      _merge(baseRollbarConfig.payload, options?.payload)
      rollbar.configure(baseRollbarConfig)

      // console.log('rollbarConfig.error', error)
      // console.log('rollbarConfig.info', info)
      // console.log('rollbarConfig.baseRollbarConfig', baseRollbarConfig)
      // console.log('rollbarConfig.app.config', app.config)

      // Send data to Rollbar - this actually sends the data to our Rollbar account:
      if (!import.meta.env.DEV) {
        rollbar.error(error);
      }
      
      // Below log the error to the browser console so we can see it there as well
      console.error(error)
    };

    app.provide('rollbar', rollbar);

  },
};
