import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

import api from '@/services/api' 
import { useAppStore } from '@/stores'

export const useAuthStore = defineStore('auth', () => {

  const currentUserData = ref(null)
  const previousUrl = ref(null)
  const redirectUrl = ref(null)

  function login(authData) {
    return api.post('session', authData)
      .then(
        response => {
          // console.log('useAuthStore login.SUCCESS', previousUrl.value)
          if (response.data.ifc_jwt_token) {
            currentUserData.value = response.data
          }

          return Promise.resolve(currentUserData.value)
        },
        error => {
          // console.log('useAuthStore login.ERROR', response)
          reset()
          return Promise.reject(error)
        }
      )
  }

  function logout() {
    // console.log('useAuthStore LOGOUT', previousUrl.value)
    reset()
    const appStore = useAppStore()
    appStore.reset() // clear localStorage data left by appStore
  }

  function setRedirectUrl(url) {
    redirectUrl.value = url
  }

  const isLoggedIn = computed(() => {
    return currentUserData.value?.id ? true : false
  })

  function reset() {
    redirectUrl.value = null
    currentUserData.value = {}
  }

  // Adding helper methods Current User for querying Roles
  const currentUser = computed(() => {
    function hasRole(role) {
      return currentUserData?.value?.roles?.includes(role)
    }

    const userDataFields = computed(() => {
      return currentUserData.value
    })

    return {
      isAdmin:          computed( () => hasRole('admin') ),
      isFundingOfficer: computed( () => hasRole('fo') ),
      isFoAlternate:    computed( () => hasRole('fo_alternate') ),
      isLoggedIn:       isLoggedIn,
      hasRole:          hasRole,
      // extract currentUserData fields
      ...userDataFields.value,
      // previousUrl:      computed(() => previousUrl.value),
    }
  })

  return {
    currentUserData,  // << this data gets stored in localStorage
    currentUser,      // << this is the currentUser helper, with extra methods attached
    previousUrl,
    setRedirectUrl,
    redirectUrl,
    login,
    logout,
    isLoggedIn,
  }
}, { persist: { paths: ['currentUserData', 'previousUrl'] } })
