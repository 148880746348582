<template lang="pug">
a(:href="params.hrefString") {{ params.email }}
</template>

<script setup>
import { ref, reactive, watchEffect } from 'vue'

const props = defineProps({
	deal: 		{ type: Object, default: null },
	// or ...
	email: 		{ type: String, default: '' },
	name: 		{ type: String, default: null },
	// subject: 	{ type: String, default: null },
	// body: 		{ type: String, default: null },
})

let params = reactive({
	name: ref(''),
	email: ref(''),
	hrefString: ref(''),
})

watchEffect(() => {
	if (props.deal) {
		params.email = props.deal.email
		params.name = props.deal.full_name
	} else {
		params.email = props.email
		params.name = props.name
	}

	if (params.name) {
		params.hrefString = `mailto:\"${params.name}\"<${params.email}>`	
	} else {
		params.hrefString = `mailto:${params.email}`
	}
	// console.log("MailToLink onMounted", params.hrefString, params.email, params.name, params.props)
})

</script>