import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import _find from 'lodash/find'
import _sortBy from 'lodash/sortBy'

export const usePersonStore = defineStore('person', () => {
  const filingOfficers =   ref([])
  const fundingOfficers =  ref([])
  const notaries =         ref([])
  const users =            ref([])

  function getUser(params) {
    return _find(users.value, params)
  }

  const fundingOfficerOptions = computed(() => {
    return fundingOfficers.value
            .filter(fo => fo.active == true && (fo.roles.includes('fo') || fo.roles.includes('fo_alternate')) )//['fo', 'fo_alternate'].includes(fo.role))
            .map(fo => { return {initials: fo.initials, full_name: fo.full_name ,name: `${fo.full_name} (${fo.initials})`} })
  })

  const activeFundingOfficers = computed(() => {
    return fundingOfficers.value.filter(fo => fo.active == true && fo.roles.includes('fo'))
  })

  const alternateFundingOfficers = computed(() => {
    return fundingOfficers.value.filter(fo => fo.active == true && fo.roles.includes('fo_alternate'))
  })

  const inactiveFundingOfficers = computed(() => {
    return fundingOfficers.value.filter(fo => fo.active == false)
  })

  function getNotary(params) {
    return _find(notaries.value, params)
  }

  function notaryOptions(currentValue) {
    // we use currentValue to set the selectedOption 
    // we include the currently selected notary even if it is disabled so that it shows in the dropdown
    let options = notaries.value
      .filter((n) => !n.disabled || n.name_and_title == currentValue)
      .map((n) => {
        return {
          label: n.display_name,
          value: n.name_and_title,
          initials: n.initials,
          disabled: n.disabled,
        }
      })
    // sort options
    options = _sortBy(options, ['label'])

    // add an empty option at the top to allow dropdown to be set to null
    options.splice(0, 0, {label: '--', value: null, disabled: false})

    const selectedOption = options.find((n) => {
      // console.log('personStore', n.value, currentValue);
      return n.value==currentValue;
    })

    // we return both the selected option, and all options to be use by the component
    return { selectedOption, options }
  }

  function reset() {
    filingOfficers.value  = []
    fundingOfficers.value = []
    notaries.value        = []
    users.value           = []
  }

  return {
    filingOfficers,

    fundingOfficers,
    activeFundingOfficers,
    alternateFundingOfficers,
    inactiveFundingOfficers,
    fundingOfficerOptions,

    notaries,
    getNotary,
    notaryOptions,

    users,
    getUser,

    reset,
  }
},
{
  persist: true,
})
