<template lang="pug">

    Avatar(
        shape="square"
        :image="props.user?.avatar_url"
        :size="props.size"
        :class="cssClass"
    )

    //- pre props.size: {{ props.size }}
    //- pre cssClass: {{ cssClass }}
    //- pre user: {{ user }}
</template>

<script setup>
  import { computed } from 'vue'

  const props = defineProps({
    user:   { type: Object, required: true, default: {} },
    size:   { type: String, default: 'normal' },
  })

  const cssClass = computed(() => {
    const strArr = []
    switch (props.size) {
      case '2xl':
        strArr.push('p-avatar-2xl')
        break
      case '3xl':
        strArr.push('p-avatar-3xl')
        break
      case 'expand':
        strArr.push('p-avatar-expand')
        break
    }

    return strArr.join(' ')
  })

  // const status     = computed(() => props.status || props.deal.status);
  // const dealNumber = computed(() => props.deal.deal_number);
  // const initials   = computed(() => props.deal.fo_initials);
  // const isNoGood   = computed(() => props.deal.no_good_flag);
  // const isOnHold   = computed(() => props.deal.on_hold_flag);
  // const isHoldBack = computed(() => props.deal.holdback_flag);
</script>
