<template lang="pug">
.grid.dashboard-section-header(style="margin-top: -2rem;" class="py-4 xl:py-2")
  .col(class="sm:col-12 lg:col-6 flex justify-content-between align-items-center px-4")
      div
        template(v-if="userList.length > 1")
          .flex-start
            h3.mb-0 AGP for {{ selectedOfficer.full_name }}
            UserAvatarGroup.mx-2(:users="userList")
        template(v-if="userList.length == 1")
          .flex-start
            h3.my-0 AGP for
            UserAvatar.mx-2(:user="userList[0]")
            h3.my-0 {{ selectedOfficer.full_name }}

        .text-3xl.text-600 {{ formatDateRange(dateRange.startDate, dateRange.endDate) }}

      .mx-2
        Dropdown(
          v-model="selectedOfficer"
          :options="groupedOfficers"
          optionLabel="label"
          optionGroupLabel="groupLabel"
          optionGroupChildren="officers"
          placeholder="Select an Officer" 
          class="w-full")
  .col(class="sm:col-12 lg:col-6 app-v-center")

    .formgrid.grid.p-fluid.w-full
      DateRangeDropdown(:dateRange="dateRange" :showLabel="false")
      DateRangeStartEnd(:dateRange="dateRange" :showLabel="false")
      .field.col-1.mb-0
        Button(@click="refreshData" severity="info" icon="pi pi-refresh" v-tooltip.top="'Refresh Data'" outlined style="margin-top: 1px;")

AgpCharts(v-if="iselectedOfficerSet" :dateRange="dateRange" :selectedOfficer="selectedOfficer" @change="handleAgpChartUpdate")

PayoffsChart(:dateRange="dateRange")

PipelineTable(v-if="iselectedOfficerSet" :selectedOfficer="selectedOfficer")

</template>

<script setup>
import { ref, onMounted, computed, watch, toRaw } from 'vue'
import { RouterLink } from 'vue-router'
import { useAppStore, useAuthStore, usePersonStore } from '@/stores'
import { pageSuccess, pageError }  from '@/modules/HttpResponse'

import pipelineService  from '@/services/dashboard/pipeline.service'
import AgpCharts from '@/views/dashboard/pipeline/AgpCharts.vue'
import PayoffsChart from '@/views/dashboard/pipeline/PayoffsChart.vue'
import PipelineTable from '@/views/dashboard/pipeline/PipelineTable.vue'
import DateRangeDropdown from '@/components/DateRangeDropdown.vue'
import DateRangeStartEnd from '@/components/DateRangeStartEnd.vue'
import { formatDateRange } from '@/utils/format'

const appStore            = useAppStore();
const authStore           = useAuthStore();
const personStore         = usePersonStore();

const defaultDateRange = {
  startDate: dayjs().startOf('month').toDate(),
  endDate:   dayjs().endOf('day').toDate()
}
const dateRange               = ref({})

const defaultAllOfficersOption = {
  initials: null, 
  label: 'All Officers', 
  full_name: 'All Officers'
}
const selectedOfficer         = ref({})

const userList = ref([])
function handleAgpChartUpdate(data) {
  userList.value = data.userList
  // console.log('userList', userList.value, userList.value.length)
}

const groupedOfficers = computed(() => {
  let activeFundingOfficers = personStore.activeFundingOfficers.map((fo) => toRaw(fo))
  activeFundingOfficers.unshift(defaultAllOfficersOption)

  return [
    {
      groupLabel: 'Active Officers',
      code: 'AO',
      officers: activeFundingOfficers,
    },
    {
      groupLabel: 'Other Officers',
      code: 'OTO',
      officers: personStore.alternateFundingOfficers,
    },
    {
      groupLabel: 'Old Officers',
      code: 'OlO',
      officers: personStore.inactiveFundingOfficers,
    }    
  ]
})

const iselectedOfficerSet = computed(() => {
  return Object.keys(selectedOfficer.value).length > 0
})

function refreshData() {
  selectedOfficer.value = { ...selectedOfficer.value }
  dateRange.value = { ...dateRange.value }
}

onMounted(() => {
  appStore.load().then((res) => {

    // Set the selected Officer depending on who the current user is
    if ( authStore.currentUser?.hasRole('fo') && authStore.currentUser?.fo_initials ){
      const { full_name, initials } = authStore.currentUser
      selectedOfficer.value = {
        initials,
        full_name,
        name: `${full_name} (${initials})`, 
      }
    } else {
      selectedOfficer.value = defaultAllOfficersOption
    }
    // set the date range
    dateRange.value = defaultDateRange
  })
});
</script>