<script setup>
import AppMenu from './AppMenu.vue';
import { useLayout } from './composables/layout';
import { useRouter } from 'vue-router';

const router = useRouter();

const navigateToDashboard = () => {
    router.push('/');
};

const { layoutState } = useLayout();

let timeout = null;

const onMouseEnter = () => {
    if (!layoutState.anchored.value) {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        layoutState.sidebarActive.value = true;
    }
};

const onMouseLeave = () => {
    if (!layoutState.anchored.value) {
        if (!timeout) {
            timeout = setTimeout(() => (layoutState.sidebarActive.value = false), 300);
        }
    }
};

const anchor = () => {
    layoutState.anchored.value = !layoutState.anchored.value;
};
</script>

<template>
    <div class="layout-sidebar" @mouseenter="onMouseEnter()" @mouseleave="onMouseLeave()">
        <div class="sidebar-header">
            <!-- IFC Logo -->
            <a @click="navigateToDashboard" class="app-logo cursor-pointer">
                <img src="/images/ifc-logo-wide-white.png" class="app-logo-normal">
                <img src="/images/ifc-logo-square-large.png" class="app-logo-single">
            </a>
            <button class="layout-sidebar-anchor p-link" type="button" @click="anchor()"></button>
        </div>

        <div ref="menuContainer" class="layout-menu-container">
            <AppMenu></AppMenu>
        </div>
    </div>
</template>

<style lang="scss" scoped></style>
