import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useLocationsStore = defineStore('locations', () => {
  const geoStates = ref(null)
  const counties  = ref(null)

  function reset() {
    geoStates.value = null
    counties.value  = null
  }

  return {
    geoStates,
    counties,

    reset,
  }
},
{
  persist: true,
})
