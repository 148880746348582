<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();

const navigateToDashboard = () => {
    router.push('/');
};
</script>
<template lang="pug">
h1.text-6xl.text-100.text-center Page Not Found
Button.w-full.text-center.mt-4(@click="navigateToDashboard" label="<< Back")
</template>
