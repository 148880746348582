<template lang="pug">
RouterLink.deal-link(:to=" '/deals/' + props.deal.id " :target="props.target") 
  template(v-if="slots.default")
    slot
  template(v-else)
    template(v-if="props.deal.full_name")
      span {{ props.deal.full_name }}
    template(v-else)
      span {{ props.deal.first_name }} {{ props.deal.last_name }}  
</template>

<script setup>
import { useSlots } from 'vue'
import { RouterLink } from 'vue-router'

const slots = useSlots()

const props = defineProps({
  deal: {
    type: Object,
    default: {},
  },
  target: {
    type: String,
    default: null,
  },
})
</script>