<template lang="pug">
	a(:href="parsedUrl" target="_blank") {{ labelToShow }}
	//- pre parsedUrl: {{ parsedUrl }}
	//- pre labelToShow: {{ labelToShow }}
	//- pre isValidUrl: {{ isValidUrl(parsedUrl) }}
	//- pre hasHttp: {{ hasHttp(props.url) }}
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
	url:	{ type: String, required: true, default: null },
	label: 	{ type: String, default: null },
})

const parsedUrl = computed(() => {
	let url = props.url
	if (!hasHttp(url)) {
		url = `https://${props.url}`
	}
	return url
})

const labelToShow = computed(() => {
	return props.label ? props.label : props.url
})

function hasHttp(urlString) {
	return urlString && (urlString.includes('https://') || urlString.includes('http://'))
}

function isValidUrl(urlString) {
    try {
    	new URL(urlString)
    	return true
    }
    catch (e) { 
    	return false 
    }
}

</script>