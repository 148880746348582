import { useAppStore } from '@/stores'
import { formatDate, toDate }  from '@/utils/format'

export const pageSuccess = (success, severity = 'success') => {
  const appStore  = useAppStore();
  appStore.setIsPageLoading(false);
  // console.log("pageSuccess", success);
  var message = success && success.message;
  if (message) {
    appStore.notify({severity: severity, detail: message, life: 5000 })
  }
}

export const pageError = (error) => {
	const appStore  = useAppStore();
  appStore.setIsPageLoading(false);

  let errors;
  let title;

  if (error.response) {
    let data = error.response.data

    // In dev env data is string, in prod env data is object
    if (typeof data === 'string') {
      data = {errors: [error.response.statusText], message: 'Server Error'}
    }else if((typeof data === 'object') && data.error) {
      data = {errors: [data.error], message: 'Server Error'}
    }

    errors = data && data.errors;
    title =  data && data.message;
  } else if (error != undefined) {
    console.log("undefined")
    errors = [error.message];
    title  = error.name;
  } else {
    errors = ['Errors'];
    title = "Page Error";
  }

  appStore.notify({severity: 'error', summary: title, detail: errors.join(','), life: 3000 })

  // console.log("pageError", error, errors, title);
  // return throwError(errors);  
}

export const formattedResponse = (response) => {
  let overridenProperties = {}

  if (response.meta.resource == 'estates'){
    overridenProperties = {
      date_of_death:               toDate(response.data.date_of_death),
      trust_date:                  toDate(response.data.trust_date),
      executor_appointed_date:     toDate(response.data.executor_appointed_date),
      executor2_appointed_date:    toDate(response.data.executor2_appointed_date),
      probate_opened_date:         toDate(response.data.probate_opened_date),
      follow_up_insurance_date:    toDate(response.data.follow_up_insurance_date),
      follow_up_other_date:        toDate(response.data.follow_up_other_date),
      follow_up_distribution_date: toDate(response.data.follow_up_distribution_date),
      follow_up_hearing_date:      toDate(response.data.follow_up_hearing_date),
    }
  }

  return {...response.data, ...overridenProperties}
}