import axios from 'axios';
import { useAuthStore, useAppStore } from '@/stores'
import router from '@/router'

const api = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  patch: request('PATCH'),
  delete: request('DELETE'),
  blobPost: request('blobPost'),
  exportCSV: request('exportCSV'),
};

const customAxios = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'text/javascript, application/javascript, application/x-ecmascript, */*; q=0.01',
    'X-Requested-With': 'XMLHttpRequest'
  },
})

function request(method) {
  return (url, body={}) => {
    const requestOptions = {
      url,
      method,
      headers: authHeader(url)
    };

    // ------------------------------------------------
    // Default GET request
    // ------------------------------------------------
    if (method == 'GET') {
      requestOptions.params = body
      requestOptions.data = {}

    // ------------------------------------------------
    // blobPost is used when sending a POST request and we 
    // want to get back a `blob` type, used to preview PDF Documents
    // ------------------------------------------------
    } else if (method == 'blobPost') {
      requestOptions.method = 'POST'
      requestOptions.data = body
      requestOptions.responseType = 'blob'

    // ------------------------------------------------
    // exportCSV is used when we want to download a CSV file
    // ------------------------------------------------
    } else if (method == 'exportCSV') {
      requestOptions.method = 'GET'
      requestOptions.params = body
      requestOptions.responseType = 'blob'

    // ------------------------------------------------
    // POST, PUT, PATCH & DELETE requests
    // ------------------------------------------------
    } else {
      requestOptions.data = body
    }

    return customAxios(requestOptions)
      .then(response => {
        return Promise.resolve(response);
      }).catch(err => {
        const authStore = useAuthStore();
        const {status, message} = err.response.data
        const appStore = useAppStore();
        // console.log('api.js Error caught:', status, message, err)

        switch (err.response.status) {
        case 401: // Log user out if Unauthenticated          
          authStore.logout();
          router.push('/login')
          break

        case 403: // Unauthorized
          let { errors, message } = err.response.data
          const errorTitle = message
          const path = err.response.config?.url
  
          appStore.setMessages([{content: `Cannot access path: ${path}  -- Please ensure you have proper permissions`, severity: 'warn'}])
          break

        case 400:
        case 422: // Unprocessable Entity
          err.response.data.errors.forEach(err => {
            appStore.setMessages([{content: err, severity: 'warn'}])
          })
          break

        case 500:
          let backtraceText
          if (typeof err.response?.data === 'string') {
            backtraceText = err.response?.data
          } else {
            backtraceText = err.response?.statusText
          }
          appStore.setMessages([
            {
              content: 'Uh oh, Server Error - An email has been sent to our team!',
              severity: 'error',
              extraInfo: {
                content: backtraceText,
              },
            }
          ])
          break
        }
        
        return Promise.reject(err);  
      })
  }
}

// helper functions
function authHeader(url) {
  const authStore = useAuthStore();

  if (authStore.isLoggedIn) {
    return { Authorization: `Bearer ${authStore.currentUser.ifc_jwt_token}` };
  } else {
    return {}
  }
}

export default api;