<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import passwordService from '@/services/password.service'
import { pageSuccess, pageError }  from '@/modules/HttpResponse'

const route  = useRoute()
const router = useRouter();
const password = ref();
const passwordChangedsuccess = ref(false);
const timeout = ref(null);
const passwordResetToken = ref(null)
const isPageLoading = ref(false)

import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/yup';
import * as yup from 'yup';

const props = defineProps({
  query: { type: Object }
})

const initialValues = {
  password: null,
}

const schema = toTypedSchema(
  yup.object({
    password: yup.string()      
      .required("Password can't be blank.") 
      .min(10, 'is not long enough (10 character minimum)')
      .max(70, 'is too long (70 characters max)')
      .matches(/(?=.*?[A-z])/, 'needs at least one Alphabet character')
      .matches(/(?=.*?[0-9])/, 'needs at least one Numeric character')
      .matches(/(?=.*?[#?!@$%^&*-+=.;'":,])./, 'needs at least one Special character')
  })
)

const {
  handleSubmit,
  errors,
  setErrors,
  controlledValues,
  defineComponentBinds,
  setFieldValue,
} = useForm({
  validationSchema: schema,
  initialValues: initialValues,
});

const formInputs = ref({
  password: defineComponentBinds('password'),
})

const savePassword = handleSubmit(values => {
  isPageLoading.value = true

  passwordService
    .putUpdate(route.params.id, { password_reset:{ password: values.password }, token: passwordResetToken.value})
    .then(success => {
      pageSuccess(success)
      passwordChangedsuccess.value = true

      timeout.value = setTimeout(() => {
        router.push('/');;
      }, 3000);
    })
    .finally(() => isPageLoading.value = false)
})

onMounted(() => {
  passwordService
    .getEdit(route.params.id, { token: props.query.token })
    .then(response => {
      passwordResetToken.value = response.password_reset_token
    })
    .catch(err => {
      pageError(err)
      router.push('/forgot-password')
    })
})

onUnmounted(() => {
  if (timeout.value){
    clearTimeout(timeout.value);    
  }
})

</script>

<template lang="pug">
.card.bg-yellow-200.text-lg
    .font-bold Please make sure your password has:
    ul.text-left
      li At least 10 characters
      li At least one letter
      li At least one number
      li At least one special character like ! @ # * & ^ % $

.card 
  form(@submit.prevent="savePassword")
    .mb-2
      h2 Change Your Password

      .my-4.text-green-700(v-show="passwordChangedsuccess")
        .text-lg Password has been successfully !!
        .text-lg Now redirecting to login page...
      
      .p-input-icon-left.w-full.mb-4
        i.pi.pi-lock
        InputText#password.w-full.surface-50.border-200(v-bind="formInputs.password" type='password' placeholder='Password' invalid)
    .my-2.text-lg.text-red-600 {{ errors.password }}  

    Button.w-full.mt-2(v-ripple='' type="submit" label='Save')

</template>