<template lang="pug">
.field.col.mb-0
    label(v-if="showLabel") Date Range
    Dropdown.w-full(
      v-model="selectedRange"
      :options="timePeriodOptions"
      optionLabel="label"
      optionValue="value"
      placeholder="Time Period"
      scrollHeight="400px"
    )

    //- pre timePeriodOptions: {{ timePeriodOptions }}
    //- pre dateRange: {{ dateRange }}
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'

const props = defineProps({
  dateRange: { type: Object, required: true },
  showLabel: { type: Boolean, required: false, default: true },
})

const emit = defineEmits(['change'])

const selectedRange = ref()

// --------------------------------------------
// Synchronize Dropdown to any changes made to props.dateRange
// -- Example:  if dateRange is set to the first and last day of the year
//              the dropdown will stay in sync and show "This Year"
// --------------------------------------------
watch(() => [props.dateRange.startDate, props.dateRange.endDate], (valueArr) => {
  // cast all date values to Dayjs for comparison
  const newStart = dayjs(valueArr[0])
  const newEnd   = dayjs(valueArr[1])

  allRanges.value.forEach((range) => {
    const checkStart = dayjs(range.start)
    const checkEnd = dayjs(range.end)
    // console.log('DateRangeDropdown.watch props.dateRange CHECK', newStart, newEnd, checkStart, checkEnd)
    if (newStart && newEnd && 
        newStart.isSame(checkStart, 'day') && 
        newEnd.isSame(checkEnd, 'day') ) 
      {
        selectedRange.value = range.value
      }
  })
})

// Emit new date range values whenever the dropdown value changes
watch(selectedRange, (newVal) => {
  const range = allRanges.value.find((r) => r.value === newVal)

  if (range.start && range.end) {
    props.dateRange.startDate = range.start
    props.dateRange.endDate = range.end
    emit('change')
  }
})

const timePeriodOptions = computed(() => {
  // extract labels and values from our defined ranges
  return allRanges.value.map( ({label, value}) => {
    return {label, value}
  })
})

const allRanges = computed(() => {
  return [
    { 
      label: 'None',
      value: null,
      start: null,
      end:   null,
    },
    { 
      label: 'Today',
      value: 'today',
      start: dayjs().startOf('day').toDate(),
      end:   dayjs().endOf('day').toDate(),
    },
    { 
      label: 'This Month',
      value: 'this-month',
      start: dayjs().startOf('month').toDate(),
      end:   dayjs().endOf('month').toDate(),
    },
    { 
      label: 'Last Month',
      value: 'last-month',
      start: dayjs().subtract(1, 'month').startOf('month').toDate(),
      end:   dayjs().subtract(1, 'month').endOf('month').toDate(),
    },
    { 
      label: 'This Quarter',
      value: 'this-quarter',
      start: dayjs().startOf('quarter').toDate(),
      end:   dayjs().endOf('quarter').toDate(),
    },
    { 
      label: 'Last Quarter',
      value: 'last-quarter',
      start: dayjs().subtract(1, 'quarter').startOf('quarter').toDate(),
      end:   dayjs().subtract(1, 'quarter').endOf('quarter').toDate(),
    },
    { 
      label: 'This Year',
      value: 'this-year',
      start: dayjs().startOf('year').toDate(),
      end:   dayjs().endOf('year').toDate(),
    },
    { 
      label: 'Last Year',
      value: 'last-year',
      start: dayjs().subtract(1, 'year').startOf('year').toDate(),
      end:   dayjs().subtract(1, 'year').endOf('year').toDate(),
    },
  ]
})

</script>
