<script setup>
  import { computed } from 'vue'
  import { RouterView, useRoute } from 'vue-router'
  import { useAppStore } from '@/stores'
  import layouts from '@/views/_layouts'

  const appStore = useAppStore()
  const route = useRoute()

  const layout = computed(() => layouts[appStore.layout] || 'div');
</script>

<template>
  <Toast />
  <!-- <keep-alive :max="5"> -->
    <component :is="layout" :key="route.fullPath">
      <template #default>
        <router-view #default/>
      </template>
    </component>
  <!-- </keep-alive> -->
</template>

<style scoped></style>
