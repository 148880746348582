<template lang="pug">

//- isLinked is used to control whether the deal badge is wrapped inside an <a> tag or not
//- The DOM elements are identical whether we link the deal or not

template(v-if="isLinked")
  RouterLink(:to=" '/deals/' + props.deal.id " style="display: contents;")
    .deal-badge(:class="dealStatus.cssClass(status)" :style="props.noMargins ? 'margin: 0;' : '' ")
      .deal-badge-top-half
        .status-text {{ status }}
      .deal-badge-bottom-half(:class="{'on-hold': Boolean(isOnHold), 'holdback': Boolean(isHoldBack), 'no-good': Boolean(isNoGood)}")
        span.initials-text(nowrap) {{ initials }} {{ dealNumber }}

template(v-else)
  .deal-badge(:class="dealStatus.cssClass(status)" :style="props.noMargins ? 'margin: 0;' : '' ")
    .deal-badge-top-half
      .status-text {{ status }}
    .deal-badge-bottom-half(:class="{'on-hold': Boolean(isOnHold), 'holdback': Boolean(isHoldBack), 'no-good': Boolean(isNoGood)}")
      span.initials-text(nowrap) {{ initials }} {{ dealNumber }}

</template>

<script setup>
  import dealStatus from '@/utils/dealStatus'
  import { computed } from 'vue'

  const props = defineProps({
    deal:      { type: Object, required: true },
    status:    { type: String },
    noMargins: { type: Boolean, default: false },
    isLinked:  { type: Boolean, default: true },
  })

  const status     = computed(() => props.status || props.deal.status);
  const dealNumber = computed(() => props.deal.deal_number);
  const initials   = computed(() => props.deal.fo_initials);
  const isNoGood   = computed(() => props.deal.no_good_flag);
  const isOnHold   = computed(() => props.deal.on_hold_flag);
  const isHoldBack = computed(() => props.deal.holdback_flag);
</script>
