<script setup>
  import { ref, computed, watch, onMounted } from 'vue';

  const props = defineProps({
    modelValue:   {},
    iconPosition: { type: String, default: 'left' },
    iconName:     { type: String },
    placeholder:  { type: String }, 
  })

  const emit  = defineEmits(['update:modelValue'])

  const model = ref()

  const iconClass = computed(() => {
    return `pi pi-${props.iconName}`
  })

  watch(() => props.modelValue, (newVal) => {
    model.value = newVal
  })

  watch(model, (newVal) => {
    emit('update:modelValue', newVal)
  })

  onMounted(() => {
    model.value = props.modelValue
  })
</script>

<template lang="pug">
IconField(:iconPosition="iconPosition")
  InputIcon(:class="iconClass")
  InputText(v-model="model" :placeholder="placeholder")
</template>