<script setup>
import { ref } from 'vue';
import { usePrimeVue } from 'primevue/config';
import { useLayout } from '@/_freya_layout/freya_theme/composables/layout';

const { layoutConfig } = useLayout();
const PrimeVue = usePrimeVue();

let currentColorScheme = layoutConfig.colorScheme.value
const toggleColorScheme = () => {
    const newColorScheme = currentColorScheme == 'light' ? 'dark' : 'light'
    PrimeVue.changeTheme(`theme-${currentColorScheme}`, `theme-${newColorScheme}`, 'theme-link', () => {
        layoutConfig.colorScheme.value = newColorScheme;
        layoutConfig.menuTheme.value = newColorScheme;
        // console.log('Color Scheme changed', currentColorScheme, newColorScheme)
        currentColorScheme = newColorScheme
    });
}
</script>
  
<template lang="pug">
Button(
  type="button" 
  :icon="layoutConfig.colorScheme.value === 'dark' ? 'pi pi-sun' : 'pi pi-moon' " 
  class="p-button-text p-button-secondary flex-shrink-0" 
  @click="toggleColorScheme()"
)
</template>
