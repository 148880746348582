<template lang="pug">

AvatarGroup()
  UserAvatar(
    v-for="(user, idx) in showUsers"
    :user="user"
    :size="props.size"
    v-tooltip.top="tooltipText(user)"
  )
  Avatar(
    v-if="hiddenAvatarCount > 0"
    :label=" `+${hiddenAvatarCount}` "
  )

//- pre showUsers: {{ showUsers }}
//- pre props: {{ props }}
</template>

<script setup>
  import { computed, ref } from 'vue'

  const props = defineProps({
    users:    { type: [Object], required: true, default: [] },
    size:     { type: String, default: 'normal' },
    showMax:  { type: Number, default: -1 },
  })

  const hiddenAvatarCount = ref(0)

  const showUsers = computed(() => {
    const max = props.showMax
    if (max > 0) {
      hiddenAvatarCount.value = props.users.length - max
      return props.users.slice(0, max)
    } else {
      return props.users
    }
  })

  function tooltipText(user) {
    return `${user.full_name} (${user.initials})`
  }
</script>
