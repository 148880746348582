import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useToast } from "primevue/usetoast";

export const useNotificationStore = defineStore('notification', () => {
  const messages = ref([])

  const toast = useToast()
  function notify(options) {
    toast.add(options);
  }

  function setMessages(msgs) {
    msgs.forEach((msg) => {
      messages.value.push({
        content: msg.content,
        severity: msg.severity,
        id: messages.value.length+1,
        // *Optional* - we can pass in extraInfo = {content: '...'}
        //              to show a button inside the message to show extra content
        extraInfo: msg.extraInfo,
      })
    })
  }

  function reset() {
    messages.value = []
  }

  return {
    messages,
    notify,
    setMessages,
    reset,
  }
},
{
  // We do not want to persist global messages in localStorage.
  // Messages should dissappear once page is reloaded.
  persist: false,
})
