import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import _sortBy from 'lodash/sortBy'

import appService from '@/services/app.service';

import {
  useDropdownStore,
  useLocationsStore,
  useNotificationStore,
  usePersonStore,
} from '@/stores/index'

export const useAppStore = defineStore('app', () => {

  // we are using nested stores
  const dropdownStore      = useDropdownStore()
  const locationsStore     = useLocationsStore()
  const notificationStore  = useNotificationStore()
  const personStore        = usePersonStore()

  const isPageLoading =    ref(false)
  const layout =           ref(null)

  const lastRefreshedAt = ref(null)
  const railsConfig = ref({})
  const javascriptConfig = {
    version: import.meta.env.VITE_CODE_VERSION,
    environment: import.meta.env.MODE,
  }

  function setLayout(newLayout) {
    layout.value = newLayout
  }

  function setIsPageLoading(flag) {
    isPageLoading.value = flag
  }

  function load() {
    return appService.init().then(
      response => {
        locationsStore.geoStates    = response.geo_states || []
        locationsStore.counties     = response.counties || []

        personStore.fundingOfficers = response.funding_officers || []
        personStore.filingOfficers  = response.filing_officers || []
        personStore.notaries        = response.notaries || []
        personStore.users           = response.users || []

        dropdownStore.noGoodReasons = _sortBy(response.no_good_reasons, ['category', 'name'])
        dropdownStore.statuses      = response.statuses || []
        dropdownStore.webSources    = response.web_sources || []

        railsConfig.value           = response.rails_config
        lastRefreshedAt.value       = response.last_refreshed_at
        // console.log('railsConfig', railsConfig)
        return Promise.resolve(response);
      },
      error => {
        reset()
        return Promise.reject(error);
      }
    );
  }

  function reset() {
    dropdownStore.reset()
    locationsStore.reset()
    personStore.reset()
    railsConfig.value = {}
  }

  // ------------------------------
  // helpers for nested stores
  // ------------------------------
  // dropdownStore
  const noGoodReasons = computed(() => {
    return dropdownStore.noGoodReasons
  })
  const statuses = computed(() => {
    return dropdownStore.statuses
  })
  const webSources = computed(() => {
    return dropdownStore.webSources
  })

  // locationStore
  const geoStates = computed(() => {
    return locationsStore.geoStates
  })
  const counties = computed(() => {
    return locationsStore.counties
  })

  // personStore
  const filingOfficers = computed(() => {
    return personStore.filingOfficers
  })
  const fundingOfficers = computed(() => {
    return personStore.fundingOfficers
  })
  const notaries = computed(() => {
    return personStore.notaries
  })
  const users = computed(() => {
    return personStore.users
  })
  function getUser(params){
    return personStore.getUser(params)
  }

  // notificationStore
  function notify(params){
    return notificationStore.notify(params)
  }
  function setMessages(params){
    return notificationStore.setMessages(params)
  }

  return {
    // appStore vars
    layout,
    isPageLoading,
    javascriptConfig,
    railsConfig,
    lastRefreshedAt,
    // appStore funcs
    setLayout,
    setIsPageLoading,
    load,
    reset,

    // dropdownStore
    noGoodReasons,
    statuses,
    webSources,

    // locationsStore
    geoStates,
    counties,

    // personStore
    filingOfficers,
    fundingOfficers,
    notaries,
    users,
    getUser,

    // notificationStore
    notify,
    setMessages,
  }
}, 
{
  persist: { paths: ['layout', 'isPageLoading'] } // Do *not* persist toRoute
  // persist: true, // piniaPluginPersistedState - save state to localStorage
})
