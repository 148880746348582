<template>
  <MegaMenu
    ref="root"
    v-bind="$attrs"
    v-on="$attrs"
    :pt="{
      content: ({ instance, context }) => ({
        onmouseenter: (event) => enter(event, instance, context),
        onmouseleave: (event) => leave(event, instance, context),
      }),
      panel: ({ instance }) => ({
        onmouseenter: (event) => enter(event, instance),
        onmouseleave: (event) => leave(event, instance),
      }),
      // menuItem: ({ instance, navigate }) => ({
      //   onmouseenter: (event) => enter(event, instance),
      //   onmouseleave: (event) => leave(event, instance, navigate),
      // }),
    }"
  />
</template>

<script setup>
import { ref } from 'vue';

const root = ref();

let timeout = 0;
let ctx = {};

const props = defineProps({
  delay: {
    type: Number,
    default: 100,
  },
});

// const emit = defineEmits(['blur'])

const isMobile = () => root.value.$el.classList.contains('p-megamenu-mobile');

function enter(event, instance, context) {
  if (isMobile()) return;

  ctx = context;

  clearTimeout(timeout);
  if (context && !context.item.level && !context.active)
    timeout = setTimeout(() => {
      instance.onItemClick(event, context.item);
    }, props.delay);
}

function leave(event, instance, context) {
  if (isMobile()) return;

  clearTimeout(timeout);
  if (!context || (!context.item.level && context.active))
    timeout = setTimeout(() => {
      console.log('HoverMegaMenu leave', instance, 'context', context, 'ctx', ctx)
      instance.onItemClick(event, (context || ctx).item);
      // instance.onItemActionClick(event);
      instance.$emit('blur');
    }, props.delay);
}
</script>
