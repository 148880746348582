<script setup>
  import { computed } from 'vue'
  import dealStatus from '@/utils/dealStatus'

  const props = defineProps({
    deal:      { type: Object, required: true },
    size:      { type: String, required: false, default: 'small' },
  })

  const salesSummary = computed(() => {
    return props.deal.sales_summary
  })

  const dealStatusCode = computed(() => {
    return salesSummary.value?.deal_status_code
  })

  const cssClasses = computed(() => {
    let strArr = []
    strArr.push( dealStatus.cssClass(dealStatusCode.value) )

    // switch (salesSummary.value?.status) {
    //   case 'prospect':
    //     strArr.push('prospect-bg')
    //   case 'qualified':
    //     strArr.push('qualified-bg')
    //   case 'funded':
    //     strArr.push('funded-bg')
    // }

    switch (props.size) {
      case 'large':
        strArr.push('is-large')
    }

    // console.log('SalesStatusBadge.cssClasses', dealStatusCode.value, dealStatus.cssClass(dealStatusCode.value))
    // combine the deal status coloring with the size class
    return strArr.join(' ')
  })

  const hasSalesData = computed(() => {
    return salesSummary.value?.has_sales_data
  })

  const tooltipText = computed(() => {
    const ss = salesSummary.value
    return `Status:\t\t ${ss?.status}
      Name:\t\t ${ss?.campaign?.name || '--'}
      Source:\t\t ${ss?.campaign?.source || '--'}
      Medium:\t\t ${ss?.campaign?.medium || '--'}
      GCLID:\t\t ${ss?.has_gclid ? 'YES' : 'NO'}
    `
  })
</script>

<template lang="pug">
template(v-if="hasSalesData")
  .sales-summary-tag(:class="cssClasses" v-tooltip.bottom="tooltipText")
    .sales-status {{ salesSummary.status_code }}
    .sales-details
      .sales-source {{ salesSummary.campaign?.source }} | {{ salesSummary.campaign?.medium }}
      //- .sales-name {{ salesSummary.campaign?.name }}
    .sales-gclid-icon
      i.pi.pi-check(v-if="salesSummary.has_gclid" style="font-weight: bolder; font-size: 0.75rem;")
      i.pi.pi-minus(v-if="!salesSummary.has_gclid" style="font-size: 0.75rem;")

template(v-else)
  .sales-summary-tag.no-data-bg(:class="{'is-large': size == 'large'}")
    .mx-2 No Sales Data

//- pre cssClasses: {{ cssClasses }}
//- pre salesSummary: {{ salesSummary }}
</template>

<style>

</style>

