<template lang="pug">
LoadingArea(:loading="isLoading")
    .chart-header-w.flex-start.my-2.py-2(
        :class="{'is-hidden': isHidden}"
        style="cursor: pointer;"
        @click="toggleVisibility"
    )
        h4.my-0.ml-2(
            style="color: unset;"
        ) Payoffs Chart
        
        .ml-3.text-sm {{ isHidden ? '+ show' : '- hide' }}
        //- Tag.p-tag-secondary.ml-4(
        //-     :icon="isHidden ? 'pi pi-plus' : 'pi pi-minus'"
        //-     :class="isHidden ? 'text-gray-300' : 'text-gray-400'"
        //-     severity="secondary"
        //-     rounded
        //- ) {{ isHidden ? 'show' : 'hide' }}

    Transition(name="fade-left")
        .card(v-if="hasData && !isLoading && !isHidden")
            Chart(type="line" :data="chartData" :options="chartOptions" class="h-30rem")

    Transition(name="fade-left")
        .flex.flex-center(v-if="!hasData && !isLoading && !isHidden" style="min-height: 4rem;") 
            .text-2xl.font-medium.text-gray-500 no Payoff data

//- div
    pre hasData: {{ hasData }}
    .grid
        .col
            pre dateRange: {{ dateRange }}
            pre chartData: {{ chartData }}
        .col
            pre params: {{ params }}
            pre chartOptions: {{ chartOptions }}
        .col
            pre rawResponse: {{ rawResponse }}

</template>

<style lang="scss" scoped>
.chart-header-w {
    border-radius: .25rem;
    padding: 0.25rem .5rem;
    &.is-hidden {
        background-color: rgb(0 0 0 / 5%);
        color: var(--gray-400);        
    }
    &:hover {
        background-color: var(--white-alpha-60);
        color: var(--blue-500);
    }
}
</style>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'
import { formatDate, formatCurrency } from '@/utils/format'
import externalTooltipHandler from '@/modules/chart/ExternalTooltip';
import pipelineService  from '@/services/dashboard/pipeline.service'

const props = defineProps({
  dateRange: { type: Object, required: true },
});

// onMounted(() => {
//   loadChart();
// });

const isLoading = ref(true)
const chartData = ref()
const chartOptions = ref()
const rawResponse = ref()
const isHidden = ref(true)

const documentStyle = getComputedStyle(document.documentElement);
const textColor = documentStyle.getPropertyValue('--text-color');
const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
const surfaceBorderColor = documentStyle.getPropertyValue('--surface-border');

const toggleVisibility = () => {
    isHidden.value = !isHidden.value
}

watch(() => [props.dateRange.startDate, props.dateRange.endDate], (newVal) => { 
    // console.log('PayoffsChart', 'watch', newVal, params, params.value.start_date)
    if (params.value.start_date) {
        loadChart()
    }
})

const params = computed(() => {
    if (props.dateRange == undefined) return {}
    return {
        start_date: dayjs(props.dateRange.startDate?.toISOString()).utc(true).toString(),
        end_date: dayjs(props.dateRange.endDate?.toISOString()).utc(true).endOf('day').toString(),
        groupBy: 'day',
    }
})

const hasData = computed(() => {
  return chartData.value?.datasets[0]?.data?.length
})

function loadChart() {
  isLoading.value = true
  pipelineService.getPayoffsChart(params.value).then((response) => {
    rawResponse.value = response
    chartData.value = parseChartData(response)
    chartOptions.value = setChartOptions()
  })
  .finally(() => isLoading.value = false)
}

const parseChartData = (response) => {
    let data = response.chartData
    let xAxisLabels = response.chartLabels

    // Cumulative Payoffs series
    const seriesOne = {
        label: 'Cumulative Payoffs',
        data: [],
        fill: true,
        pointStyle: 'circle',
        pointRadius: 2,
        borderWidth: 2,
        borderColor: documentStyle.getPropertyValue('--blue-500'),
        backgroundColor: '#2196f359', // blue with alpha ~ 25%
        tension: 0.4,
        yAxisID: 'yLeft',
        segment: {
            borderColor: ctx => isSkipped(ctx, 'rgb(0,0,0,0.2)'),
            borderDash: ctx => isSkipped(ctx, [6, 6]),
        },
        spanGaps: true,
        tooltipLabel: 'Cumulative',
    }
    // Cumulative Deals series
    const seriesTwo = {
        label: 'Cumulative Deals',
        data: [],
        type: 'line',
        fill: true,
        borderWidth: 1,
        borderColor: documentStyle.getPropertyValue('--purple-500'),
        backgroundColor: '#9c27b040', // purple with alpha ~ 25%
        tension: 0.4,
        yAxisID: 'yRight',
        tooltipLabel: 'Cum. Deals',
    }
    // Daily Payoff Total series
    const seriesThree = {
        label: 'Daily Payoffs',
        data: [],
        type: 'bar',
        fill: true,
        borderWidth: 2,
        borderColor: documentStyle.getPropertyValue('--green-500'),
        backgroundColor: documentStyle.getPropertyValue('--green-100'),
        tension: 0.4,
        yAxisID: 'yLeft',
        tooltipLabel: 'Daily Payoff',
    }
    // Number of Deals series
    const seriesFour = {
        label: 'Daily Deals',
        data: [],
        type: 'bar',
        fill: true,
        borderWidth: 1,
        borderColor: documentStyle.getPropertyValue('--orange-500'),
        backgroundColor: documentStyle.getPropertyValue('--orange-100'),
        tension: 0.4,
        yAxisID: 'yRight',
        tooltipLabel: 'Daily Deals',
    }

    data.forEach((item) => {
        // console.log('data', item.cumulative_payoffs, item.date)
        seriesOne.data.push(item.cumulative_payoffs)            
        seriesTwo.data.push(item.cumulative_deals)            
        seriesThree.data.push(item.total_payoffs)            
        seriesFour.data.push(item.count)
    })

    return {
        labels: xAxisLabels,
        datasets: [
            seriesOne,
            seriesTwo,
            seriesThree,
            seriesFour,
        ]
    }
}

const setChartOptions = () => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    return {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        interaction: {
          intersect: false,
          mode: 'index',
        },        
        plugins: {
            legend: {
                position: 'top',
                labels: {
                  color: textColor
                },
            },
            tooltip: {
                // https://stackoverflow.com/a/75911895/580419
                // this filter should limit when the tooltip to triggers/shows up
                filter: function (tooltipItem) {
                    // console.log(tooltipItem.raw)
                    // raw == the x axis value - here I am ignoring when the chart value is zero
                    return tooltipItem.raw !== undefined
                    // return tooltipItem.datasetIndex === 0  // <<  only for the first dataset
                },
                callbacks: {
                    title: function(tooltipItems) {
                        const tItem = tooltipItems[0]
                        return tItem?.raw ? `Payoffs: ${formatDate(tooltipItems[0]?.label)}` : '--'
                    },
                    label: function(tooltipItem) {
                        // console.log('tooltip.label', tooltipItem)
                        let lbl
                        switch (tooltipItem.datasetIndex) {
                        case 0:
                        case 2:
                            lbl = `${tooltipItem.dataset.tooltipLabel}:\t $${tooltipItem.formattedValue}`
                            break
                        case 1:
                        case 3:
                            lbl = `${tooltipItem.dataset.tooltipLabel}:\t ${tooltipItem.formattedValue} deals`
                            break
                        }
                        return lbl
                    },
                    // footer: function(tooltipItems) {
                    //     return 'Total: ' + formatCurrency(tooltipItems.reduce((a, b) => a + b.parsed.y, 0));
                    // },
                },
                enabled: false,
                position: 'nearest',
                external: externalTooltipHandler            
            },
        },
        scales: {
            x: {
                type: 'time',
                ticks: {
                    color: textColorSecondary,
                },
                grid: {
                    color: surfaceBorder
                },
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'MMM DD, YY'
                    }
                }
            },
            yLeft: {
                position: 'left',
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            },
            yRight: {
                position: 'right',
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder,
                    drawOnChartArea: false,
                }
            },
        }
    };
}

const isSkipped = (ctx, value) => ctx.p0.skip || ctx.p1.skip ? value : undefined;
</script>